@import "./custom.less";
@animation-duration: 5s;

@header: header0;
.@{header}.scrolled {
  position: fixed !important;
  background: #ffffff !important;
}
.@{header} {
  background: linear-gradient(to bottom, #5e5e5e, #ffffff00);
  opacity: 1;
  width: 100%;
  z-index: 99;
  // box-shadow: 0 5px 8px fade(#000, 15);
  position: absolute;
  top: 0;
  transition: all 0.5s;
  .home-page {
    padding: 0 30px;
  }
  &-logo.scrolled {
    line-height: 70px;
  }
  &-logo {
    display: inline-block;
    position: relative;
    width: 200px;
    line-height: 100px;
    left: 10%;
    & img {
      vertical-align: middle;
      display: inline-block;
      transition: all 0.5s;
    }
    & img.scrolled {
      width: 20%;
      max-width: 80px !important;
      left: -15% !important;
      line-height: 80px !important;
    }
    & a {
      display: block;
    }
  }
  &-menu {
    float: right;
    padding-top: 5px;
    > .ant-menu {
      line-height: 80px;
      background: transparent;
      color: #f40f0f;
      height: 64px;
      border-bottom-color: transparent;
      position: relative;
      font-size: larger;
      a {
        color: #fff;
        &:hover {
          color: #f40f0f;
        }
      }
    }
  }
  &-menu.scrolled {
    float: right;
    padding-top: 5px;
    > .ant-menu {
      line-height: 65px;
      background: transparent;
      color: #f40f0f;
      height: 64px;
      border-bottom-color: transparent;
      position: relative;
      font-size: larger;
      a {
        color: #000;
        &:hover {
          color: #f40f0f;
        }
      }
    }
  }
  .ant-menu-item-selected a {
    color: #fff;
    font-weight: bold;
  }
  .ant-menu-item-selected::after {
    border-bottom-color: #f40f0f !important;
  }
  .ant-menu-submenu-selected::after {
    border-bottom-color: #f40f0f !important;
  }

  .ant-menu-item:hover::after {
    border-bottom-color: #f40f0f !important;
  }
  .ant-menu-submenu:hover::after {
    border-bottom-color: #f40f0f !important;
  }

  .ant-menu-submenu-open::after {
    border-bottom-color: #f40f0f !important;
  }
}

@media screen and (max-width: 767px) {
  // .@{header}.scrolled {
  //   background: #863434 !important;
  // }
  .@{header} {
    border-bottom: none;
    height: auto;
    &-logo {
      z-index: 101;
      left: 1%;
      width: 150px;
      & img.scrolled {
        width: 100%;
        max-width: 120px !important;
      }
    }
    &.home-page-wrapper .home-page {
      padding: 0 24px;
    }
    & &-menu {
      height: auto;
      float: inherit;
      position: relative;
      left: -24px;
      width: ~"calc(100% + 48px)";
      // opacity: 0;
      transition: opacity 0.3s @ease-in-out, height 0.3s @ease-in-out;
      & li {
        padding: 0 24px;
        &.ant-menu-submenu {
          padding: 0;
        }
      }
      & .ant-menu-submenu .ant-menu-sub {
        padding: 0 24px;
      }
    }
    &-mobile-menu {
      width: 16px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 40%;
      right: 24px;
      z-index: 100;
      em {
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        margin-top: 4px;
        transition: transform 0.3s @ease-in-out, opacity 0.3s @ease-in-out;
      }
      :first-child {
        margin-top: 0;
      }
    }
    .ant-menu {
      height: auto;
      overflow: hidden;
      // background: @template-bg-color;
      .ant-menu-title-content a {
        color: #000;
      }
      .ant-menu-item-selected a {
        color: #000;
        font-weight: bold;
      }
      .ant-menu-item-selected::after {
        border-bottom-color: #f40f0f !important;
      }
      .ant-menu-submenu-selected::after {
        border-bottom-color: #f40f0f !important;
      }

      .ant-menu-item:hover::after {
        border-bottom-color: #f40f0f !important;
      }
      .ant-menu-submenu:hover::after {
        border-bottom-color: #f40f0f !important;
      }

      .ant-menu-submenu-open::after {
        border-bottom-color: #f40f0f !important;
      }
    }
    & .open {
      height: auto;
      .@{header}-mobile-menu {
        em {
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }
      > .@{header}-menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .em-scrolled {
    background: #000 !important;
  }
}
