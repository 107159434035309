body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.contact-us-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.contact-us-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.contact-us-wrapper .contact-us {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.contact-us-wrapper .title-wrapper > h1,
.contact-us-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.contact-us-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contact-us-wrapper .contact-us {
    padding: 56px 24px;
  }
  .contact-us-wrapper .contact-us > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .contact-us-wrapper .contact-us > h1.title-h1 {
    margin-bottom: 8px;
  }
  .contact-us-wrapper .contact-us > p {
    margin-bottom: 32px;
  }
}
.content13-wrapper {
  max-height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: lighten;
  margin: 0 auto;
  overflow: hidden;
}
.content13-wrapper.contact-us-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
.content13-contactus-wrapper {
  max-height: 450px;
  background: url("/public/images/contactus-bg.webp") bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: lighten;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0;
}
.content13-contactus-wrapper.contact-us-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-contactus-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
  .content13-contactus-wrapper {
    max-height: 320px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("/public/images/home/banner 1.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    height: 45vh;
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
