// @import "~antd/lib/style/v2-compatible-reset.less";

body {
  word-wrap: break-word;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */

.video {
  max-width: 800px;
}

#react-content {
  min-height: 100%;
}
.contact-us-wrapper p {
  padding: 0;
  margin: 0;
}
