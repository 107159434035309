body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.markets-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.markets-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 10vh;
}
.markets-wrapper .markets {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.markets-wrapper .title-wrapper > h1,
.markets-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.markets-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .markets-wrapper {
    padding-top: 8vh;
  }
  .markets-wrapper .title-wrapper > h1,
  .markets-wrapper > h1 {
    font-size: 26px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
    margin-top: 8px !important;
  }
  .markets-wrapper .title-wrapper {
    margin: 0 auto 10px !important;
    text-align: center;
  }
  .markets-wrapper .markets {
    padding: 10px 10px;
  }
  .markets-wrapper .markets > h1 {
    font-size: 10px;
    margin: 0 auto 32px;
  }
  .markets-wrapper .markets > p {
    margin-bottom: 32px;
  }
  .markets-wrapper .market-overpack-block {
    padding: 10px;
    text-align: center;
  }
  .markets-childpage {
    padding: 8vh 10px 4vh;
  }
  .markets-tabs-wrapper {
    position: relative;
    margin-top: 24px;
    text-align: center;
  }
  .ant-list-item {
    text-align: left;
  }
  .ant-col > .ant-list-item {
    margin-block-end: 2px !important;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 48px;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
