@content9: content9;
@text-color: rgba(0, 0, 0, 0.85);
@text-color2: #697b8c;
.@{content9}-wrapper {
  padding-bottom: 64px;
  &.about-us-wrapper {
    overflow: initial;
  }
  .@{content9} {
    min-height: 800px;
    padding: 64px 0 0;
  }
  .timeline {
    position: relative;
    &:before {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 0;
      height: ~"calc(100% - 108px)";
      margin: 34px 0;
      border-left: 2px #ebedf0 dashed;
    }
  }
  .block-wrapper {
    color: @text-color;
    display: flex;
    position: relative;
    margin-bottom: 70px;
    min-height: 160px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .image-wrapper,
  .text-wrapper {
    width: 50%;
  }
  .image-wrapper {
    text-align: center;
    .block-img,
    .name-wrapper {
      float: right;
      clear: both;
      width: 262px;
      margin: auto;
    }
    .block-img {
      height: 98px;
      img {
        height: 100%;
      }
    }
    .name-wrapper {
      margin: 16px 0 0;
      .block-name {
        font-size: 14px;
        margin-bottom: 4px;
      }
      .block-post {
        font-size: 12px;
        color: @text-color2;
      }
    }
  }
  .text-wrapper {
    padding-left: 40px;
    .block-time {
      font-size: 12px;
      color: @text-color2;
      line-height: 18px;
      min-height: 18px;
    }
    .block-title {
      margin: 8px 0 12px;
      font-size: 14px;
      position: relative;
      min-height: 18px;
    }
    .block-icon {
      position: absolute;
      left: -40px;
      transform: translateX(~"calc(-50% + 1px)");
    }
    .block-content {
      width: 300px;
      color: #314659;
      font-size: 12px;
      min-height: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content9}-wrapper {
    padding-bottom: 0;
    .@{content9} {
      padding: 64px 24px;
    }
    .timeline {
      &:before {
        left: 0;
        height: 100%;
        margin: 0;
      }
    }
    .block-wrapper {
      display: block;
      min-height: 100px;
      margin-bottom: 54px;
    }
    .image-wrapper,
    .text-wrapper {
      width: 100%;
    }
    .image-wrapper {
      .block-img {
        display: none;
      }
      .name-wrapper {
        text-align: left;
        float: left;
        width: 100%;
        padding-left: 40px;
        margin: auto;
      }
      .block-name,
      .block-post {
        display: inline-block;
      }
      .block-name {
        margin-right: 8px;
      }
    }
    .text-wrapper {
      .block-content {
        display: none;
      }
      .block-title {
        font-size: 16px;
      }
    }
  }
}
