@content6: whatwedo-about-us;
.@{content6}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 5vh;
  min-height: 720px;
  .@{content6} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
    display: flex;
    align-items: center;

    &-text {
      min-height: 424px;
      > *.queue-anim-leaving {
        position: relative !important;
      }
      .title-h1 {
        position: relative;
        margin: 0 0 16px;
        text-align: left;
        font-size: 36px;
        font-weight: normal;
      }
      .title-content {
        position: relative;
        margin-bottom: 64px;
        text-align: left;
        font-size: 16px;
      }
      ul {
        position: relative;
        display: inline-block;
        list-style-type: none;
        li {
          margin-bottom: 24px;
          .@{content6}-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
          }
          .@{content6}-title,
          .@{content6}-content {
            margin-left: 45px;
            font-size: 16px;
          }
          .@{content6}-title {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .@{content6}-wrapper {
    .@{content6} {
      padding: 26px 24px;
      > h1 {
        font-size: 24px;
        margin: 0 auto 32px;
        &.title-h1 {
          margin-bottom: 8px;
        }
      }
    }
    margin-top: 0vh;
    height: 660px;
    overflow: hidden;
    .@{content6} {
      display: block;
      &-img,
      &-text {
        display: block;
        width: 100%;
      }
      &-text {
        > h1,
        > p {
          text-align: center;
        }
        > h1 {
          margin: 56px auto 16px;
        }
        p {
          margin-bottom: 32px;
        }
        ul {
          li {
            .@{content6}-icon {
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
            }
            margin-bottom: 14px;
            .@{content6}-content {
              margin-left: 45px;
              font-size: 14px;
            }
            .@{content6}-title {
              font-size: 16px;
              margin-bottom: 4px;
            }
          }
        }
        .title-content {
          position: relative;
          margin-bottom: 24px;
          text-align: center;
          font-size: 16px;
        }
      }
      &-img {
        margin-top: 20px;
      }
      .title-wrapper > h1,
      > h1 {
        font-size: 24px;
        color: @text-color;
        margin-bottom: 16px;
        text-align: center;
        font-weight: bold;
      }
      .title-wrapper {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
