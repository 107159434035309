@content13: content13;
.@{content13}-wrapper {
  max-height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: lighten;
  margin: 0 auto;
  overflow: hidden;
  &.contact-us-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

.@{content13}-contactus-wrapper {
  max-height: 450px;
  background: url("/public/images/contactus-bg.webp") bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: lighten;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0;
  &.contact-us-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content13}-wrapper {
    padding-bottom: 0;
  }
  .@{content13}-contactus-wrapper {
    max-height: 320px;
  }
}
