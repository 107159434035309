body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.footer-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.footer-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.footer-wrapper .footer {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.footer-wrapper .title-wrapper > h1,
.footer-wrapper > h1 {
  font-size: 32px;
  margin-bottom: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.footer-wrapper .title-wrapper {
  text-align: center;
}
.footer {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .footer-wrapper .footer {
    padding: 56px 24px;
  }
  .footer-wrapper .footer > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .footer-wrapper .footer > h1.title-h1 {
    margin-bottom: 8px;
  }
  .footer-wrapper .footer > p {
    margin-bottom: 32px;
  }
}
.footer1-wrapper {
  background: #fff;
  overflow: hidden;
  position: relative;
  min-height: 320px;
  color: black;
}
.footer1-wrapper .footer1 .footer {
  padding: 64px 24px 20px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
  margin-bottom: 50px;
}
.footer1-wrapper .block .slogan {
  font-size: 16px;
  margin-top: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  word-wrap: break-word;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #black;
}
.footer1-wrapper .block a {
  color: black;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #0689ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .footer {
  padding: 0 24px 10px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 20px;
  text-align: center;
  line-height: 20px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .footer {
    padding: 24px 14px 12px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 .block .logo {
    max-width: 180px;
    margin-bottom: 10px;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .footer {
    padding: 0;
    padding-bottom: 20px;
  }
  .footer1 .copyright-wrapper .footer .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.contactusform-wrapper {
  min-height: 10px;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.contactusform-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.contactusform-wrapper .title-content {
  line-height: 32px;
}
.contactusform-wrapper .form-wrapper {
  margin: 0 auto 0px;
  text-align: center;
}
.contactusform-wrapper .ant-input-number {
  width: 100%;
}
.ant-form-item .ant-form-item-label > label {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: black !important;
}
.ant-select-selector {
  color: black !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
@media screen and (max-width: 767px) {
  .contactusform-wrapper {
    padding-bottom: 0;
  }
  .contactusform-wrapper .form-wrapper {
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item .ant-form-item-label {
    padding-bottom: 1px;
  }
  .ant-form-item:nth-last-child(2) {
    margin-bottom: 10px;
  }
  .ant-form-item {
    width: 80%;
  }
  .ant-space-gap-col-small {
    column-gap: 4px;
  }
}
