@homepage: markets;
.@{homepage}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 10vh;
  .@{homepage} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .@{homepage}-wrapper {
    padding-top: 8vh;
    .title-wrapper > h1,
    > h1 {
      font-size: 26px;
      color: @text-color;
      margin-bottom: 4px;
      margin-top: 8px !important;
    }

    .title-wrapper {
      margin: 0 auto 10px !important;
      text-align: center;
    }
    .@{homepage} {
      padding: 10px 10px;
      > h1 {
        font-size: 10px;
        margin: 0 auto 32px;
      }
      > p {
        margin-bottom: 32px;
      }
    }
    .market-overpack-block {
      padding: 10px;
      text-align: center;
    }
  }
  .@{homepage}-childpage {
    padding: 8vh 10px 4vh;
  }

  .@{homepage}-tabs-wrapper {
    position: relative;
    margin-top: 24px;
    text-align: center;
    // h4 {
    // margin-top: 24px;
    // }
  }
  .ant-list-item {
    text-align: left;
  }
  .ant-col > .ant-list-item {
    margin-block-end: 2px !important;
  }
}
