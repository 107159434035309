body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0.scrolled {
  position: fixed !important;
  background: #ffffff !important;
}
.header0 {
  background: linear-gradient(to bottom, #5e5e5e, #ffffff00);
  opacity: 1;
  width: 100%;
  z-index: 99;
  position: absolute;
  top: 0;
  transition: all 0.5s;
}
.header0 .home-page {
  padding: 0 30px;
}
.header0-logo.scrolled {
  line-height: 70px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 200px;
  line-height: 100px;
  left: 10%;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
  transition: all 0.5s;
}
.header0-logo img.scrolled {
  width: 20%;
  max-width: 80px !important;
  left: -15% !important;
  line-height: 80px !important;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
  padding-top: 5px;
}
.header0-menu > .ant-menu {
  line-height: 80px;
  background: transparent;
  color: #f40f0f;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  font-size: larger;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #f40f0f;
}
.header0-menu.scrolled {
  float: right;
  padding-top: 5px;
}
.header0-menu.scrolled > .ant-menu {
  line-height: 65px;
  background: transparent;
  color: #f40f0f;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  font-size: larger;
}
.header0-menu.scrolled > .ant-menu a {
  color: #000;
}
.header0-menu.scrolled > .ant-menu a:hover {
  color: #f40f0f;
}
.header0 .ant-menu-item-selected a {
  color: #fff;
  font-weight: bold;
}
.header0 .ant-menu-item-selected::after {
  border-bottom-color: #f40f0f !important;
}
.header0 .ant-menu-submenu-selected::after {
  border-bottom-color: #f40f0f !important;
}
.header0 .ant-menu-item:hover::after {
  border-bottom-color: #f40f0f !important;
}
.header0 .ant-menu-submenu:hover::after {
  border-bottom-color: #f40f0f !important;
}
.header0 .ant-menu-submenu-open::after {
  border-bottom-color: #f40f0f !important;
}
@media screen and (max-width: 767px) {
  .header0 {
    border-bottom: none;
    height: auto;
  }
  .header0-logo {
    z-index: 101;
    left: 1%;
    width: 150px;
  }
  .header0-logo img.scrolled {
    width: 100%;
    max-width: 120px !important;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-title-content a {
    color: #000;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #000;
    font-weight: bold;
  }
  .header0 .ant-menu .ant-menu-item-selected::after {
    border-bottom-color: #f40f0f !important;
  }
  .header0 .ant-menu .ant-menu-submenu-selected::after {
    border-bottom-color: #f40f0f !important;
  }
  .header0 .ant-menu .ant-menu-item:hover::after {
    border-bottom-color: #f40f0f !important;
  }
  .header0 .ant-menu .ant-menu-submenu:hover::after {
    border-bottom-color: #f40f0f !important;
  }
  .header0 .ant-menu .ant-menu-submenu-open::after {
    border-bottom-color: #f40f0f !important;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .em-scrolled {
    background: #000 !important;
  }
}
.homepagebanner {
  width: 100%;
  height: 90vh;
  position: relative;
  border-color: #666;
  background: #fff;
  mix-blend-mode: darken;
}
.homepagebanner-wrapper,
.homepagebanner .banner-anim {
  height: 100%;
}
.homepagebanner .queue-anim-leaving {
  position: relative !important;
}
.homepagebanner .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.homepagebanner .bg0 {
  background: linear-gradient(to bottom, #00000032, #99999900), url("/public/images/home/banner 1.webp");
  background-position: 50% 55%;
}
.homepagebanner .bg2 {
  background: url("/public/images/home/banner 2.jpg") center;
}
.homepagebanner .bg3 {
  background: url("/public/images/home/banner 3.jpeg");
  background-position: 20% 40%;
  background-size: 120% !important;
}
.homepagebanner .bg4 {
  background: url("/public/images/home/banner 4.webp");
  background-position: 60% 40%;
  background-size: 70% !important;
}
.homepagebanner .bg5 {
  background: url("/public/images/home/banner 5.jpg");
  background-position: 100% 20%;
  background-size: 120% !important;
}
.homepagebanner .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.homepagebanner .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.homepagebanner .banner-user-elem .banner-user-text {
  top: 40%;
}
.homepagebanner .homepagebanner-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: -25%;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 500px;
  text-align: left;
}
.homepagebanner .homepagebanner-text-wrapper-block {
  background-color: #362f33c2;
}
.homepagebanner .homepagebanner-title {
  width: 350px;
  height: 250px;
  top: 50px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 30px;
  position: relative;
}
.homepagebanner .homepagebanner-title2 {
  width: 350px;
  height: 280px;
  top: 50px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 30px;
  position: relative;
}
.homepagebanner .homepagebanner-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.homepagebanner .homepagebanner-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.homepagebanner .homepagebanner-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.homepagebanner .homepagebanner-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.homepagebanner .homepagebanner-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.homepagebanner .homepagebanner-button.queue-anim-leaving {
  width: auto;
}
.homepagebanner .homepagebanner-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .homepagebanner {
    height: 40vh;
  }
  .homepagebanner .homepagebanner-text-wrapper {
    display: block;
    position: relative;
    top: 60%;
    left: 0%;
    right: 20%;
    margin: auto;
    font-size: 40px;
    color: #fff;
    width: 80%;
    text-align: center;
  }
  .homepagebanner .homepagebanner-text-wrapper .homepagebanner-title {
    width: 100%;
    left: 0px;
    right: 0px;
  }
  .homepagebanner .homepagebanner-text-wrapper-block {
    background-color: #362f339c;
  }
  .homepagebanner .homepagebanner-title {
    top: 20px;
    font-size: 16px;
    position: relative;
  }
  .homepagebanner .homepagebanner-title2 {
    top: 20px;
    font-size: 16px;
    left: 0%;
    width: 100%;
    position: relative;
  }
  .homepagebanner .bg {
    background-attachment: inherit;
  }
  .homepagebanner .bg0 {
    background-position: 50% 55%;
    background-size: 95% !important;
  }
  .homepagebanner .bg2 {
    background-size: 160% !important;
  }
  .homepagebanner .bg3 {
    background-position: 40% 40%;
    background-size: 180% !important;
  }
  .homepagebanner .bg4 {
    background-position: 60% 40%;
    background-size: 110% !important;
  }
  .homepagebanner .bg5 {
    background-position: 100% 20%;
    background-size: 210% !important;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  margin: auto;
}
.content0-wrapper .content0-block-countdown {
  width: 100px;
  height: 155px;
  margin: auto;
  font-size: x-large;
}
.content0-wrapper .content0-block-countdown > .countdownclasscenter {
  position: relative;
  top: 25%;
  transform: translateY(-50%);
  font-size: 70px;
  font-weight: bold;
}
.content0-wrapper .content0-block-countdown > .smoothTransition {
  animation: colorChange 1s forwards;
}
@keyframes colorChange {
  from {
    color: red;
  }
  to {
    color: blue;
  }
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 480px;
  }
  .content0-wrapper .content0 {
    padding: 0px 10px;
  }
  .content0-wrapper .content0-block {
    min-height: 100px;
    margin-bottom: 0px;
  }
  .content0-wrapper .content0-block img {
    width: 90%;
    max-height: 100px;
    object-fit: contain;
  }
  .content0-wrapper .content0-block-wrapper {
    position: relative;
    top: 25%;
    padding: 0px 0;
  }
  .content0-wrapper .content0-block-countdown {
    width: 100px;
    height: 100px;
  }
  .content0-wrapper .content0-block-title {
    line-height: 20px;
    margin: 10px auto;
    font-size: 12px;
  }
  .content0-wrapper .content0-block-countdown > .countdownclasscenter {
    top: 15%;
  }
}
.content11-wrapper {
  height: 480px;
  background: url("/public/images/envelope.webp") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 96px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content11-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.85);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.teams0-wrapper {
  height: 320px;
}
.teams0-wrapper .teams0 {
  padding: 64px 24px 0px;
  display: flex;
  align-items: flex-end;
}
.teams0-wrapper .teams0 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams0-wrapper .teams0 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams0-wrapper .teams0 .banner-anim-thumb span.active {
  background: #0689ff;
}
.teams0-wrapper .teams0 .queue-anim-leaving {
  position: relative !important;
}
.teams0-wrapper .teams0-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams0-wrapper .teams0-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.teams0-wrapper .teams0-image img {
  height: 100%;
}
.teams0-wrapper .teams0-content {
  font-size: 24px;
  color: #312f2f;
  text-align: center;
  width: 80%;
  margin: 70px auto;
}
.teams0-wrapper .teams0-content2 {
  font-size: 14px;
  color: #919191;
  text-align: center;
  width: 80%;
  margin: 8px auto;
}
.teams0-wrapper .teams0-h1 {
  font-size: 24px;
  text-align: center;
  width: 80%;
  margin: 24px auto 0;
}
@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 320px;
  }
  .teams0-wrapper .teams0 {
    padding: 14px 4px 0px;
    display: block;
  }
}
.content12-wrapper {
  background-color: #fafafa;
  min-height: 470px;
}
.content12-wrapper .content12 {
  padding: 64px 24px;
}
.content12-wrapper .content12 > p {
  text-align: center;
}
.content12-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content12-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.content12-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  height: 110px;
  align-items: center;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content12-wrapper .img-wrapper .block .block-content > span {
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    overflow: hidden;
  }
  .content12-wrapper .content12 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content12-wrapper .content12 ul li span {
    height: 168px;
  }
}
.content10-wrapper {
  height: 480px;
  background: url(/public/images/map.webp) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  animation: ScaleAnim 2s ease-in-out infinite;
  transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
  .content10-wrapper .map-tip {
    margin-left: -45%;
    margin-top: -160px;
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-7px);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.9);
  }
}
#homepagebanner_0 .banner-anim > .banner-anim-elem > .lm2komoilik-editor_css {
  cursor: null;
}
#homepagebanner_0 .banner-anim > .banner-anim-elem > .lm2kqgg72bj-editor_css {
  transition: all 5s ease 5s;
}
.clients {
  margin-top: 0px;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .clients {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
