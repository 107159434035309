@content13: contactusform;
.@{content13}-wrapper {
  min-height: 10px;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
  .form-wrapper {
    margin: 0 auto 0px;
    text-align: center;
  }
  .ant-input-number {
    width: 100%;
  }
}

.ant-form-item .ant-form-item-label > label {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: black !important;
}

.ant-select-selector {
  color: black !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
@media screen and (max-width: 767px) {
  .@{content13}-wrapper {
    padding-bottom: 0;
    .form-wrapper {
      padding-left: 5%;
      padding-right: 5%;
      text-align: center;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
    .ant-form-item-label {
      padding-bottom: 1px;
    }
    &:nth-last-child(2) {
      margin-bottom: 10px;
    }
  }
  .ant-form-item {
    width: 80%;
  }
  .ant-space-gap-col-small {
    column-gap: 4px;
  }
}
