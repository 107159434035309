body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.about-us-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.whoweare-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.whoweare-wrapper .whoweare {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.whoweare-wrapper .title-wrapper > h1,
.whoweare-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.whoweare-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .whoweare-wrapper .whoweare {
    padding: 56px 24px;
  }
  .whoweare-wrapper .whoweare > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .whoweare-wrapper .whoweare > h1.title-h1 {
    margin-bottom: 8px;
  }
  .whoweare-wrapper .whoweare > p {
    margin-bottom: 32px;
  }
  .whoweare-wrapper .whoweare .ant-form-item {
    margin-bottom: 32px;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.about-us-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 36px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
.content1-wrapper .content1-text .content1-content {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 500px;
  }
  .content1-wrapper .content1 {
    height: 500px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 0px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
    font-size: 14px;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
    font-weight: bold;
  }
}
.whatwedo-about-us-wrapper {
  margin-top: 5vh;
  min-height: 720px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us {
  height: 100%;
  display: flex;
  align-items: center;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text {
  min-height: 424px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text > *.queue-anim-leaving {
  position: relative !important;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 36px;
  font-weight: normal;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
  font-size: 16px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul {
  position: relative;
  display: inline-block;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li {
  margin-bottom: 24px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-title,
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-content {
  margin-left: 45px;
  font-size: 16px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-title {
  font-size: 20px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .whatwedo-about-us-wrapper {
    margin-top: 0vh;
    height: 860px;
    overflow: hidden;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us {
    display: block;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-img,
  .whatwedo-about-us-wrapper .whatwedo-about-us-text {
    display: block;
    width: 100%;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > h1,
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > p {
    text-align: center;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > h1 {
    margin: 56px auto 16px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text p {
    margin-bottom: 32px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-img {
    margin-top: 20px;
  }
}
.visionmission {
  margin-top: 5vh;
}
.visionmission-wrapper {
  min-height: 680px;
  margin: 0 auto;
  overflow: hidden;
}
.visionmission-wrapper.about-us-wrapper .about-us {
  padding: 100px 24px;
}
.visionmission-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.visionmission-title-h1 {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.85);
}
.visionmission-title-content {
  margin-top: 16px;
}
.visionmission-carousel {
  width: calc(100% + 72px);
  margin-left: -36px;
}
.visionmission-carousel-title {
  font-size: 20px;
  text-align: center;
}
.visionmission-carousel-title-block {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.45s;
  padding: 0 40px;
  position: relative;
  cursor: pointer;
}
.visionmission-carousel-title-block.active {
  color: rgba(0, 0, 0, 0.85);
  cursor: auto;
}
.visionmission-carousel-title-block::before {
  display: block;
  content: "";
  width: 1px;
  height: 70%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #d8d8d8;
}
.visionmission-carousel-title-block:last-child::before {
  display: none;
}
.visionmission-block {
  margin-top: 48px;
}
.visionmission-block-wrapper {
  margin-top: 32px;
}
.visionmission-block-row {
  padding: 0 36px;
}
.visionmission-block-col {
  text-align: center;
  margin: 36px 0;
  position: relative;
}
.visionmission-block-col:last-child .visionmission-block-arrow {
  display: none;
}
.visionmission-block-child {
  padding: 36px 24px 24px;
  border-radius: 6px;
  background-color: #f5f6f6;
  height: 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.visionmission-block-child:hover {
  background-color: #f6f9ff;
  box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
}
.visionmission-block-child:hover .visionmission-block-content {
  opacity: 1;
}
.visionmission-block-image {
  display: inline-block;
}
.visionmission-block-image img {
  max-width: 100%;
  background-color: #f5f6f6;
  background-blend-mode: multiply;
}
.visionmission-block-title {
  font-size: 36px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
}
.visionmission-block-content {
  opacity: 1;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 20px;
  text-align: center;
  transition: opacity 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.visionmission-block-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  top: 80px;
}
.visionmission-button {
  text-align: center;
}
.visionmission-button-wrapper {
  margin-top: 16px;
}
.visionmission-button .ant-btn {
  padding: 8px 40px;
  border-radius: 20px;
  line-height: 1.5;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #3c89f6;
  background-image: linear-gradient(135deg, #82b5ff 0%, #3c89f6 100%);
  box-shadow: 0 9px 22px rgba(95, 155, 241, 0.35);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, box-shadow;
}
.visionmission-button .ant-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 14px 26px rgba(95, 155, 241, 0.4);
}
@media screen and (max-width: 767px) {
  .visionmission-wrapper {
    padding-bottom: 0;
    min-height: 0px;
  }
  .visionmission-wrapper.about-us-wrapper .about-us {
    padding: 56px 24px 24px;
  }
  .visionmission-carousel-title {
    font-size: 16px;
  }
  .visionmission-carousel-title-block {
    padding: 0 8px;
  }
  .visionmission-title-wrapper {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .visionmission-block {
    margin-top: 0px;
  }
  .visionmission-block-col {
    margin: 10px 0;
  }
  .visionmission-block-child {
    width: 80%;
    padding: 6px 24px 4px;
    margin: auto;
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
  }
  .visionmission-block-content {
    text-align: center;
  }
  .visionmission-block-arrow {
    bottom: -40px;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
  }
  .visionmission-block-image img {
    max-height: 70px;
  }
  .visionmission-block-title {
    font-size: 26px;
    margin-top: 0px;
  }
  .visionmission-block-content {
    margin-top: 10px;
  }
}
.whatwedo-about-us-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 5vh;
  min-height: 720px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  display: flex;
  align-items: center;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text {
  min-height: 424px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text > *.queue-anim-leaving {
  position: relative !important;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 36px;
  font-weight: normal;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
  font-size: 16px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul {
  position: relative;
  display: inline-block;
  list-style-type: none;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li {
  margin-bottom: 24px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-title,
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-content {
  margin-left: 45px;
  font-size: 16px;
}
.whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.whatwedo-about-us-wrapper .title-wrapper > h1,
.whatwedo-about-us-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.whatwedo-about-us-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .whatwedo-about-us-wrapper {
    margin-top: 0vh;
    height: 660px;
    overflow: hidden;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us {
    padding: 26px 24px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us > h1.title-h1 {
    margin-bottom: 8px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us {
    display: block;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-img,
  .whatwedo-about-us-wrapper .whatwedo-about-us-text {
    display: block;
    width: 100%;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > h1,
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > p {
    text-align: center;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text > h1 {
    margin: 56px auto 16px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text p {
    margin-bottom: 32px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text ul li {
    margin-bottom: 14px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-content {
    margin-left: 45px;
    font-size: 14px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text ul li .whatwedo-about-us-title {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-text .title-content {
    position: relative;
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us-img {
    margin-top: 20px;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us .title-wrapper > h1,
  .whatwedo-about-us-wrapper .whatwedo-about-us > h1 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
  }
  .whatwedo-about-us-wrapper .whatwedo-about-us .title-wrapper {
    margin: 0 auto;
    text-align: center;
  }
}
.whoweare-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.whoweare-wrapper .whoweare {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.whoweare-wrapper .title-wrapper > h1,
.whoweare-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.whoweare-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .whoweare-wrapper .whoweare {
    padding: 56px 24px;
  }
  .whoweare-wrapper .whoweare > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .whoweare-wrapper .whoweare > h1.title-h1 {
    margin-bottom: 8px;
  }
  .whoweare-wrapper .whoweare > p {
    margin-bottom: 32px;
  }
  .whoweare-wrapper .whoweare .ant-form-item {
    margin-bottom: 32px;
  }
}
.whoweare-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.whoweare-wrapper .whoweare {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.whoweare-wrapper .title-wrapper > h1,
.whoweare-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.whoweare-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .whoweare-wrapper .whoweare {
    padding: 16px 24px 10px;
  }
  .whoweare-wrapper .whoweare > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .whoweare-wrapper .whoweare > h1.title-h1 {
    margin-bottom: 8px;
  }
  .whoweare-wrapper .whoweare > p {
    margin-bottom: 32px;
  }
  .whoweare-wrapper .whoweare .ant-form-item {
    margin-bottom: 32px;
  }
}
