@content0: content0;

.@{content0}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{content0} {
    height: 100%;
    padding: 64px 24px;

    > .title-wrapper {
      margin: 0 auto 48px;
    }

    &-block {
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
        max-height: 150px;
        object-fit: contain;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        margin: auto;
      }

      &-countdown {
        width: 100px;
        height: 155px;
        margin: auto;
        font-size: x-large;
        > .countdownclasscenter {
          position: relative;
          top: 25%;
          transform: translateY(-50%);
          font-size: 70px;
          font-weight: bold;
        }
        > .smoothTransition {
          animation: colorChange 1s forwards;
        }
        @keyframes colorChange {
          from {
            color: red;
          }
          to {
            color: blue;
          }
        }
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    min-height: 480px;

    .@{content0} {
      padding: 0px 10px;
      &-block {
        min-height: 100px;
        img {
          width: 90%;
          max-height: 100px;
          object-fit: contain;
        }
        margin-bottom: 0px;
        &-wrapper {
          position: relative;
          top: 25%;
          padding: 0px 0;
        }
        &-countdown {
          width: 100px;
          height: 100px;
        }
        &-title {
          line-height: 20px;
          margin: 10px auto;
          font-size: 12px;
        }
        &-countdown {
          > .countdownclasscenter {
            top: 15%;
          }
        }
      }
    }
  }
}
