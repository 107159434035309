.footer1-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  min-height: 320px;
  color: @template-footer-text-color;
  .footer1 {
    .footer {
      padding: 64px 24px 20px;
    }
  }
  .block {
    padding: 0 32px;
    .logo {
      max-width: 180px;
      margin-bottom: 50px;
    }
    .slogan {
      font-size: 16px;
      margin-top: 20px;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      word-wrap: break-word;
    }
    > h2 {
      margin-bottom: 24px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 12px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade(@line-color, 10);
    .footer {
      padding: 0 24px 10px;
      overflow: hidden;
    }
    .copyright {
      height: 20px;
      text-align: center;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .footer {
          padding: 24px 14px 12px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
      .logo {
        max-width: 180px;
        margin-bottom: 10px;
      }
    }
    > ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      > li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .footer {
          padding: 0;
          padding-bottom: 20px;
          .copyright {
            font-size: 12px;
          }
        }
      }

      span {
        width: 90%;
      }
    }
  }
}
