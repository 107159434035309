@homepagebanner: homepagebanner;
.@{homepagebanner} {
  width: 100%;
  height: 90vh;
  position: relative;
  border-color: #666;
  background: #fff;
  mix-blend-mode: darken;
  &-wrapper,
  .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .bg0 {
    background: linear-gradient(to bottom, #00000032, #99999900),
      url("/public/images/home/banner 1.webp");
    background-position: 50% 55%;
  }
  // .bg1 {
  //   background: url("/public/images/home/img2.webp") center;
  // }
  .bg2 {
    background: url("/public/images/home/banner 2.jpg") center;
  }
  .bg3 {
    background: url("/public/images/home/banner 3.jpeg");
    background-position: 20% 40%;
    background-size: 120% !important;
  }

  .bg4 {
    background: url("/public/images/home/banner 4.webp");
    background-position: 60% 40%;
    background-size: 70% !important;
  }

  .bg5 {
    background: url("/public/images/home/banner 5.jpg");
    background-position: 100% 20%;
    background-size: 120% !important;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  & &-text-wrapper {
    display: block;
    position: relative;
    top: 20%;
    left: -25%;
    right: 0;
    margin: auto;
    font-size: 14px;
    color: @template-text-color-light;
    width: 500px;
    text-align: left;
  }
  & &-text-wrapper-block {
    background-color: #362f33c2;
  }
  & &-title {
    width: 350px;
    height: 250px;
    top: 50px;
    left: 30px;
    margin: auto;
    display: inline-block;
    font-size: 30px;
    position: relative;
  }
  & &-title2 {
    width: 350px;
    height: 280px;
    top: 50px;
    left: 30px;
    margin: auto;
    display: inline-block;
    font-size: 30px;
    position: relative;
  }
  & &-content {
    margin-bottom: 20px;
    word-wrap: break-word;
  }
  & &-button {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    transition: background 0.45s @ease-out, box-shadow 0.45s @ease-out;
    line-height: 36px;
    font-size: 16px;
    height: 36px;
    & span {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: text-shadow 0.45s @ease-out;
    }
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
      & span {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
      }
    }

    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 767px) {
  .@{homepagebanner} {
    height: 40vh;
    & &-text-wrapper {
      display: block;
      position: relative;
      top: 60%;
      left: 0%;
      right: 20%;
      margin: auto;
      font-size: 40px;
      color: @template-text-color-light;
      width: 80%;
      text-align: center;
      .@{homepagebanner}-title {
        width: 100%;
        left: 0px;
        right: 0px;
      }
    }

    & &-text-wrapper-block {
      background-color: #362f339c;
    }

    & &-title {
      top: 20px;
      font-size: 16px;
      position: relative;
    }
    & &-title2 {
      top: 20px;
      font-size: 16px;
      left: 0%;
      width: 100%;
      position: relative;
    }
    .bg {
      background-attachment: inherit;
    }
    .bg0 {
      background-position: 50% 55%;
      background-size: 95% !important;
    }
    // .bg1 {
    //   background: url("/public/images/home/img2.webp") center;
    // }
    .bg2 {
      background-size: 160% !important;
    }
    .bg3 {
      background-position: 40% 40%;
      background-size: 180% !important;
    }

    .bg4 {
      background-position: 60% 40%;
      background-size: 110% !important;
    }

    .bg5 {
      background-position: 100% 20%;
      background-size: 210% !important;
    }
  }
}
