@content6: content6;
.@{content6}-wrapper {
  margin-top: 5vh;
  min-height: 720px;
  .@{content6} {
    height: 100%;
    display: flex;
    align-items: center;

    &-text {
      min-height: 424px;
      > *.queue-anim-leaving {
        position: relative !important;
      }
      .title-h1 {
        position: relative;
        margin: 0 0 16px;
        text-align: left;
        font-size: 36px;
        font-weight: normal;
      }
      .title-content {
        position: relative;
        margin-bottom: 64px;
        text-align: left;
        font-size: 16px;
      }
      ul {
        position: relative;
        display: inline-block;
        li {
          margin-bottom: 24px;
          .@{content6}-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
          }
          .@{content6}-title,
          .@{content6}-content {
            margin-left: 45px;
            font-size: 16px;
          }
          .@{content6}-title {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content6}-wrapper {
    margin-top: 0vh;
    height: 860px;
    overflow: hidden;
    .@{content6} {
      display: block;
      &-img,
      &-text {
        display: block;
        width: 100%;
      }
      &-text {
        > h1,
        > p {
          text-align: center;
        }
        > h1 {
          margin: 56px auto 16px;
        }
        p {
          margin-bottom: 32px;
        }
      }
      &-img {
        margin-top: 20px;
      }
    }
  }
}
