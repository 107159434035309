#homepagebanner_0 .banner-anim > .banner-anim-elem > .lm2komoilik-editor_css {
  cursor: null;
}
#homepagebanner_0 .banner-anim > .banner-anim-elem > .lm2kqgg72bj-editor_css {
  transition: all 5s ease 5s;
}

.clients {
  margin-top: 0px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .clients {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
